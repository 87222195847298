<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="createType == 'edit'"
              >Edit Lender Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="createType == 'add'">Add Lender</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="createType == 'edit'">Edit Lender Information</span
            ><span v-if="createType == 'add'">Add Lender</span>
          </h3>

          <p class="py-4">
            This is where we are going to add the lender. Lenders are a critical
            component in getting us those sweet sweet fees. Simply add their
            name, type, and areas they lend in and BOOM! Retirement.
          </p>
          <v-form
            @submit="submitCreateLender"
            ref="form"
            v-model="formRules.valid"
          >
            <v-text-field
              v-model="lenderData.lenderName"
              label="Lender Name"
              :rules="formRules.required"
            ></v-text-field>
            <v-select
              :items="lenderTypeOptions"
              v-model="lenderData.lenderType"
              label="Lender Type"
              :rules="formRules.required"
            ></v-select>
                        <v-select
              :items="lenderGroupOptions"
              v-model="lenderData.group_name"
              label="Group Name"
              
            ></v-select>
            <br />
            <v-row>
              <v-col cols="12" class="pb-0 mb-n2">Lending Areas</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="lenderData.lendBC"
                  label="BC"
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="lenderData.lendAB"
                  label="Alberta"
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="lenderData.lendON"
                  label="Ontario"
                  hide-details
                ></v-checkbox
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="lenderData.active"
                  label="Currently An Active Lender"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="createType == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateLender"
                  >Create Lender
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  v-if="createType == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateLender"
                  >Update Lender
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'edit'"
                  @click="
                    $router.push({ path: `/dashboard/lender/read/${lenderId}` })
                  "
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'add'"
                  @click="$router.push({ path: `/dashboard/lender/list` })"
                  >Go To Lender List</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderAdd",
  props: ["lenderId"],
  data() {
    return {
      createType: "",
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      lenderData: {
        lenderName: "",
        lenderType: "",
        lendAB: false,
        lendBC: false,
        lendON: false,
        group_name: null,
        active: false,
      },
      lenderGroupOptions: [
        {value: '', text: "None"},
        {value: "Round 1", text: "Round 1"},
        {value: "Round 2", text: "Round 2"},
        {value: "Round 3", text: "Round 3"},
        {value: "Round 4", text: "Round 4"},
        {value: "Hail Mary", text: "Screw it.... Hail Mary!"},
      ],
      lenderTypeOptions: [
        { value: "mic", text: "Mortgage Investment Company" },
        { value: "private", text: "Private Lender" },
        { value: "institutional", text: "Institutional" },
        { value: "other", text: "Other" },
      ],
    };
  },
  methods: {
    submitCreateLender() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/lender", this.lenderData)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(
              `/dashboard/lender/read/${res.data.insertedLenderId}`
            );
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
    submitUpdateLender() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .patch(`/lender/${this.lenderData.id}`, this.lenderData)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(`/dashboard/lender/read/${this.lenderData.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
  },
  mounted() {
    if (this.$route.name == "lenderAdd") {
      this.createType = "add";
      this.componentLoading = false;
    } else {
      this.createType = "edit";
      API()
        .get(`/lender/read/${this.lenderId}`)
        .then((res) => {
          this.lenderData = res.data.recordset[0];
          this.componentLoading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.dispatch("Auth/silentLogin");
            }
          }
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>